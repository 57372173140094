.bonus-card-section {
    .bonus-card {
        height: 420px;
        padding: 40px;
        position: relative;
        background: #ECECEC;

        .title {
            font-weight: 500;
            text-transform: uppercase;
        }

        .text {
            width: 35%;
            margin-top: 20px;
        }

        .link {
            margin-top: auto;
        }

        .text-col {
            position: relative;
            z-index: 1;
            width: 40%;
            display: flex;
            height: 100%;
            align-items: flex-start;
            flex-direction: column;
        }

        .bg {
            width: 515px;
            left: unset;
            z-index: 0;
        }
    }

    @media (max-width: 1279px) {
        .bonus-card {
            padding: 30px;
            height: 300px;

            .text-col {
                width: 38%;
            }

            .title {
                font-size: 16px;
            }

            .text {
                width: 80%;
                font-size: 14px;
            }

            .bg {
                width: 56%;
            }
        }
    }

    @media (max-width: 767px) {
        .bonus-card {
            padding: 30px 30px 20px;
            height: auto;
            aspect-ratio: 343/480;

            .bg {
                width: auto;
                left: 0;
            }

            .text-col {
                width: 100%;
            }

            .link {
                width: 100%;
            }

            .text {
                width: 80%;
            }
        }
    }
}
.orders-list {
    .orders-col{
        width: 70%;
    }
    .order-block{
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #BDBDBD;

        .order-name{
            text-transform: uppercase;
            font-weight: 500;
        }
        .order-name, .order-price, .order-delivery, .order-delivery-address{
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
        .order-delivery{
            color: #BDBDBD;
        }
        .detail-link{
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    @media (max-width: 1279px){
        .orders-col{
            width: 100%;
            margin-top: 50px;
        }
    }
    @media (max-width: 575px){
        .order-block{
            .detail-link{
                width: 100%;
                position: static;
            }
        }
    }
}
.categories-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .category-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 30px 0;
        position: relative;
        aspect-ratio: 1/1;

        .bg {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.07) linear-gradient(0.19deg, rgba(0, 0, 0, 0.6) 0.18%, rgba(0, 0, 0, 0) 99.85%);
            }
        }

        .name {
            color: $white;
            text-transform: uppercase;
            text-align: center;
            font-weight: 500;
        }
    }

    @media (max-width: 1279px) {
        .category-block {
            aspect-ratio: 372/400;
            padding: 20px 0;

            .name {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 575px) {
        grid-template-columns: 1fr;

        .category-block {
            aspect-ratio: 375/440;
        }
    }
}
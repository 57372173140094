.title-container {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
        "title link"
        "content content";

    &__title {
        margin-bottom: 33px;
        text-transform: uppercase;
        font-weight: 500;
        align-self: flex-start;
        grid-area: title;
    }

    &__link {
        align-self: flex-start;
        grid-area: link;
    }

    &__content {
        grid-area: content;
    }

    @media (max-width: 575px) {
        grid-template-areas:
            "title title"
            "content content"
            "link link";

        &__link {
            margin-top: 16px;
        }
    }
}
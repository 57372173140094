.catalog-categories-row {
    border-color: #BDBDBD;
    border-style: solid;
    border-width: 1px 0;
    
    .row{
        display: flex;
        gap: 40px;
        padding: 20px 16px;
        margin: 0 -16px;
        overflow: auto;
    }

    .link {
        white-space: nowrap;
        text-transform: uppercase;
        font-size: 14px;
    }
}
footer {
    padding: 100px 0 33px;
    background: #F9F9F9;

    .subscribe-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 62px;

        .form {
            display: flex;
            flex: 0 0 49.3%;
            flex-wrap: wrap;
            gap: 0 20px;

            .personal {
                flex: 0 0 100%;
                margin-top: 11px;
            }

            .submit-btn,
            .form-input-wrap {
                flex: 1 1 40%;
            }
        }

        .subscribe-info {
            flex: 0 0 20%;

            .title {
                text-transform: uppercase;
                font-weight: 500;
            }

            .text {
                margin-top: 10px;
            }
        }
    }

    .bottom-row {
        display: flex;
        gap: 24px;
        margin-top: 160px;
        position: relative;
        flex: 0 0 100%;

        .to-top {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            border: 1px solid $green;
            border-radius: 50%;
            transition: opacity $tr-time;

            .icon {
                width: 8px;
                height: 17px;
                fill: $green;
            }

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .menus-row {
        display: flex;
        flex-wrap: wrap;

        .menu-col {

            &.grid-menu {
                display: grid;
                grid-template-columns: repeat(2, 16vw);
                gap: 0 33px;

                .menu-name {
                    grid-column: span 2;
                }
            }
        }

        .menu-name {
            margin-bottom: 23px;
            opacity: 0.5;
        }

        .menu-link {
            display: block;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            &:hover {
                color: var(--link-hover-color);
            }
        }

        .contact {
            text-transform: uppercase;
        }

        .catalog-col {
            flex: 0 0 18.5%;
            margin-right: 90px;
        }

        .contacts-col {
            flex: 0 0 23%;
        }
    }

    @media (max-width: 1279px) {
        padding: 100px 0 26px;

        .subscribe-row {
            flex-direction: column;

            .form {
                .personal {
                    flex: 0 0 50%;
                    margin-top: 20px;
                }
            }
        }

        .subscribe-info {
            width: 50%;
            margin-bottom: 40px;
        }

        .bottom-row {
            flex-direction: column;
            gap: 16px;
            align-items: flex-start;
            margin-top: 0;
        }

        .menus-row {
            gap: 60px 0;

            .menu-link {
                font-size: 14px;
            }

            .catalog-col {
                margin: 0;
            }

            .contacts-col {
                .menu-link {
                    font-size: 12px;
                }
            }

            .catalog-col,
            .contacts-col {
                flex: 0 0 34%;
                margin-right: 40px;
            }

            .customers-col,
            .bottom-row {
                flex: 1 1 auto;
            }
        }

    }

    @media (min-width: 576px) and (max-width: 1279px) {}

    @media (max-width: 767px) {
        .menus-row {
            .menu-col {
                &.grid-menu {
                    grid-template-columns: repeat(2, auto);
                }
            }
        }
    }

    @media (max-width: 575px) {
        .subscribe-row {
            margin-bottom: 40px;

            .form {
                flex-direction: column;

                .submit-btn {
                    margin-top: 16px;
                    order: 1;
                }

                .personal {
                    margin-top: 16px;
                }
            }
        }

        .subscribe-info {
            width: 100%;
        }

        .menus-row {
            display: block;

            .menu-col {
                flex: 0 0 auto;
                margin: 0;

                &:not(:last-child) {
                    margin: 0 0 40px;
                }

                &.grid-menu {
                    grid-template-columns: 1fr;

                    .menu-name {
                        grid-column: auto;
                    }

                    .right-col {
                        margin-top: 48px;
                    }
                }
            }
        }

        .bottom-row {

            .to-top {
                display: none;
            }
        }
    }
}
.news-card {
    position: relative;
    --darken-opacity: .2;

    .name {
        width: 62%;
    }

    .img {
        width: 100%;
        height: 100%;
    }

    .img-wrap {
        aspect-ratio: 310/240;
        margin-bottom: 20px;
        background: #BDBDBD;

        &::before {
            content: "Подробнее";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #fff;
            opacity: 0;
            pointer-events: none;
            transition: opacity $tr-time;
        }
    }

    .date {
        margin-top: 20px;
        color: #BDBDBD;
    }

    &:hover {
        --darken-opacity: .4;

        .img-wrap {
            &::before {
                opacity: 1;
            }
        }
    }
}
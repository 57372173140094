.steps-list {
    .step {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        border-top: 1px solid #BDBDBD;
        border-bottom: 1px solid #BDBDBD;

        .value {
            flex: 0 0 80%;
        }
    }

    @media (max-width: 1279px) {
        .step {
            font-size: 14px;
        }
    }
}

.steps-title {
    margin-bottom: 30px;
    font-weight: 500;
    text-transform: uppercase;
}
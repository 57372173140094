.simple-slider {
    position: relative;

    .navigation-wrap {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: var(--navigation-height, 100%);
        display: flex;
        justify-content: space-between;
        z-index: 2;
        pointer-events: none;

        .nav-arrow {
            pointer-events: all;
            align-self: center;

            &.prev {
                transform: translateX(-50%);
            }

            &.next {
                transform: translateX(50%);
            }
        }
    }

    .pagination-wrap {
        position: absolute;
        top: calc(100% + 20px);
        left: 0;
        right: 0;
        z-index: 1;
    }

    @media (max-width: 1279px) {
        .slider-wrap {
            margin: 0 -36px;
            padding: 0 36px;
            overflow: hidden;

            >.swiper {
                overflow: visible;
            }
        }

        .navigation-wrap {
            display: none;
        }
    }

    @media (max-width: 575px) {
        .slider-wrap {
            margin: 0 -16px;
            padding: 0 16px;
        }
    }
}
header {
    padding: 16px 0 18px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
    color: $green;
    background: #fff;
    --action-icon-color: #{$green};
    --action-bg-color: transparent;

    .container {
        display: flex;
        justify-content: space-between;
    }

    .actions {
        display: flex;
        gap: 20px;
    }

    .menu {
        display: flex;
        gap: 24px;

        .menu-item {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: color $tr-time;

            .icon {
                width: 10px;
                height: 6px;
                margin-left: 7px;
                fill: currentColor;
            }

            &:hover {
                color: var(--link-hover-color);
            }
        }
    }

    .logo {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: opacity $tr-time;

        .icon {
            width: 100%;
            height: 100%;
        }
    }

    .compact-logo {
        width: 28px;
        height: 28px;
    }

    .full-logo {
        width: 129px;
        height: 18px;
    }

    &.fixed {
        .full-logo {
            opacity: 0;
        }
    }

    &:not(.fixed) {
        .compact-logo {
            opacity: 0;
        }
    }

    .mobile-actions {
        display: flex;
        gap: 10px;
    }

    @media (min-width: 1024px) {
        .mobile-actions {
            display: none;
        }
    }

    @media (max-width: 1023px) {
        .menu {
            display: none;
        }
    }

    @media (min-width: 576px) {
        .mobile-actions {
            .search-action {
                display: none;
            }
        }
    }

    @media (max-width: 575px) {
        .actions {
            gap: 10px;

            .search-action,
            .profile-action {
                display: none;
            }
        }

        .full-logo {
            width: 117px;
            height: 16px;
        }
    }
}
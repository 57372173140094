.overflow {
    &-hidden {
        overflow: hidden;
    }

    &-visible {
        overflow: visible;
    }

    &-auto {
        overflow: auto;
    }
}

.hidden {
    display: none !important;
}

.bg {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    pointer-events: none;

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.collapse {
    overflow: hidden;
    transition: height var(--collapse-time, #{$tr-time * 2});

    &.is-collapsed {
        height: 0;
    }
}

.link-cover {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding: 10px 22px;
    text-align: center;
    @include text-16;
    color: var(--text-color) !important;
    text-decoration: none !important;
    border: 1px solid var(--border-color);
    border-radius: 60px;
    background: var(--bg-color);
    cursor: pointer;
    user-select: none;

    &-transparent {
        --bg-color: transparent;
        --border-color: #{$green};
        --text-color: #{$green};

        &:hover {
            opacity: 0.5;
        }
    }

    &-white-transparent {
        --bg-color: transparent;
        --border-color: #{$white};
        --text-color: #{$white};

        &:hover {
            opacity: 0.5;
        }
    }

    &-white {
        --bg-color: #fff;
        --border-color: #fff;
        --text-color: #{$green};

        &:hover {
            opacity: 0.5;
        }
    }

    &-green {
        --bg-color: #{$green};
        --border-color: #{$green};
        --text-color: #fff;

        &:hover {
            opacity: 0.5;
        }
    }

    &:not(:disabled) {
        transition-duration: $tr-time;
        transition-property: color, border-color, background-color, box-shadow,
            opacity;
    }

    &:disabled {
        pointer-events: none;
        transition-duration: 0ms;
        --text-color: transparent;
    }

    @media (max-width: 1279px) {
        font-size: 14px;
    }
}

.container {
    $padding: 16px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: #{1300px + $padding*2};
    padding-left: $padding;
    padding-right: $padding;

    @media (max-width: 1279px) {
        max-width: 100%;
    }

    @media (min-width: 576px) and (max-width: 1279px) {
        padding-left: 36px;
        padding-right: 36px;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.h1 {
    @include h1;
}

.t-48 {
    @include text-48;
}

.t-32 {
    @include text-32;
}

.t-20 {
    @include text-20;
}

.t-16 {
    @include text-16;
}

.t-14 {
    @include text-14;
}

.t-12 {
    @include text-12;
}

.page-block {
    margin: 160px 0;

    &:first-child {
        margin-top: 0;
    }
}

.uppercase {
    text-transform: uppercase;
}

.darken {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(#000, var(--darken-opacity, 0.2));
        transition: background-color $tr-time;
    }
}

@media (max-width: 1279px) {
    .page-block {
        margin: 100px 0;
    }
}
.profile-page {
    margin: 0 0 140px;

    .title {
        padding-bottom: 40px;
        margin-bottom: 34px;
        border-bottom: 1px solid #BDBDBD;
        grid-column: span 2;
    }

    .container {
        display: grid;
        grid-template-columns: 20% 1fr;
    }

    .sidebar {
        .link {
            display: block;
            text-transform: uppercase;

            &:not(.active) {
                opacity: 0.5;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    .content-col {
        margin-left: auto;
    }

    .profile-col {
        width: 61.6%;

        .fields {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }

        .change-password {
            display: block;
            margin-top: 20px;
            text-transform: uppercase;
            text-decoration: underline;
        }

        .column-2 {
            grid-column: span 2;
        }
    }
    @media (max-width: 1279px) {
        margin: 0 0 100px;
        .title{
            grid-column: auto;
            font-size: 40px;
        }
        .container{
            grid-template-columns: 1fr;
        }
        .profile-col{
            margin-top: 20px;
            width: 100%;
        }
    }
    @media (max-width: 575px) {
        .title{
            font-size: 32px;
        }
        .profile-col{
            .fields{
                grid-template-columns: 1fr;
            }
            .column-2{
                grid-column: auto;
            }
            .save-btn{
                width: 100%;
            }
        }
    }
}
.clothes-categories {
    --navigation-height: 100%;

    .category-block {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 30px;
        position: relative;
        aspect-ratio: 420/580;
        overflow: hidden;

        .name {
            margin-top: auto;
            align-self: center;
            font-weight: 500;
            color: $white;
            text-transform: uppercase;
        }

        .category {
            padding: 3px 10px;
            align-self: flex-end;
            color: $white;
            border: 1px solid $white;
            border-radius: 40px;
        }

        .bg{
            transition: transform $tr-time;
        }

        &:hover{
            .bg{
                transform: scale(1.1);
            }
        }
    }

    @media (max-width: 1279px) {
        .category-block {
            padding: 20px;

            .name {
                font-size: 16px;
            }

            .category {
                font-size: 14px;
            }
        }
    }

    @media (max-width: 575px) {
        .category-block {
            aspect-ratio: 310/480;
        }

        .slider-wrap {
            padding-right: 33px;
        }
    }
}
.basket-section {
    margin-top: 0;
    .container{
        display: grid;
        grid-template-columns: 76% 1fr;
    }

    .title {
        grid-column: span 2;
        padding-bottom: 45px;
        margin-bottom: 20px;
        border-bottom: 1px solid #BDBDBD;
    }

    .basket-warnings {
        grid-column: span 2;
    }

    .basket-root{
        display: grid;
        grid-template-columns: 76% 1fr;
    }

    .basket-items-list-header {
        display: none;
    }

    .basket-item {
        width: 65%;
        display: flex;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #BDBDBD;

        .basket-item-property-scu-text {
            display: none;
        }

        .image-col {
            flex: 0 0 22%;

            .image {
                width: 100%;
                object-fit: cover;
            }
        }

        .info-col {
            position: relative;
            flex: 1 1 auto;
            padding-left: 20px;
        }

        .remove {
            position: absolute;
            top: 0;
            right: 0;
        }

        .prop {
            margin-bottom: 10px;
        }

        .name {
            display: block;
            padding-right: 25px;
            text-transform: uppercase;
        }

        .size {
            text-transform: uppercase;
        }

        .articul {
            color: #BDBDBD;
        }
    }

    .remove {
        padding: 0 0 20px 20px;

        .icon {
            width: 12px;
            height: 12px;
            fill: $green;
        }
    }

    .block-amount {
        width: 75px;
        height: 30px;
        display: inline-flex;
        font-size: 12px;
        border: 1px solid #BDBDBD;
        border-radius: 60px;

        input {
            min-width: 0;
            width: 0;
            flex: 1 1 auto;
            border: 0;
            background: transparent;
            text-align: center;
        }

        .amount-btn {
            flex: 0 0 30%;
            line-height: 2;

            &.minus {
                text-align: right;
            }

            &.plus {
                text-align-last: left;
            }
        }
    }

    @media (max-width: 1279px) {
        .container{
            grid-template-columns: 1fr;
        }
        .title {
            padding-bottom: 20px;
        }

        .title,
        .basket-warnings {
            grid-column: auto;
        }

        .basket-root {
            grid-template-columns: 1fr;
        }

        .basket-total{
            margin-top: 100px;
        }

        .basket-item {
            width: 100%;

            .image-col {
                flex: 0 0 30%;
            }
        }
    }

    @media (min-width: 576px) and (max-width: 767px) {
        .basket-total {
            margin-bottom: 20px;
        }

        .basket-items-list-wrapper {
            order: 1;
        }
    }
}

.lookbook-section {
    .title-row {
        display: flex;
        margin-bottom: 76px;

        .title {
            flex: 0 0 50%;
        }

        .description {
            flex: 0 0 25%;
            margin-top: 14px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }

    @media (max-width: 1279px) {
        .grid {
            gap: 16px;
        }

        .title-row {
            margin-bottom: 40px;
            flex-direction: column;

            .description {
                margin-top: 20px;
                font-size: 16px;
            }

            .title {
                font-size: 40px;
            }
        }
    }

    @media (max-width: 575px) {
        .grid {
            grid-template-columns: 1fr;
            gap: 40px;
        }

        .title-row {
            .title {
                font-size: 32px;
            }
        }
    }
}
.order-section {
    padding-bottom: 100px;

    #bx-soa-order {
        display: grid;
        grid-template-columns: 70% 1fr;
        gap: 30px;
    }

    .bx-soa-sidebar {
        height: 100%;
    }

    .bx-soa-cart-total {
        position: sticky;
        top: 80px;
        left: 0;
    }

    .bx-soa-section-title-container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .bx-soa .bx-soa-customer-label,
    .bx-soa .bx-soa-custom-label,
    .bx-soa .form-group label {
        display: inline-block;
        padding-bottom: 5px;
        margin-bottom: 2px;
    }

    .bx-soa-more-btn {
        display: flex;
        gap: 10px;
    }

    .bx-soa-pp-company-selected {
        display: flex;
        align-items: center;

        img {
            width: 30px;
            height: 30px !important;
            object-fit: cover;
        }
    }

    .bx-soa-section-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .has-error .form-control {
        border-color: #a94442;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    }

    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }

    .tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        word-wrap: normal;
        white-space: normal;
        filter: alpha(opacity=0);
        opacity: 0;
        line-break: auto;
    }

    .tooltip.top {
        padding: 5px 0;
        margin-top: -3px;
    }

    .tooltip.top .tooltip-arrow {
        bottom: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000;
    }

    .tooltip-inner {
        max-width: 200px;
        padding: 3px 8px;
        color: #fff;
        text-align: center;
        background-color: #000;
        border-radius: 4px;
    }

    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    .bx-soa-tooltip.bx-soa-tooltip-danger .tooltip-arrow {
        border-top-color: #F2DEDE;
    }

    .bx-soa-tooltip.bx-soa-tooltip-static .tooltip-arrow {
        left: 20px;
    }

    .bx-soa-pp {
        display: grid;
        grid-template-columns: 60% 1fr;
    }

    .bx-soa-pp-item-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .btn-default {
        --bg-color: transparent;
        --border-color: #{$green};
        --text-color: #{$green};

        &:hover {
            opacity: 0.5;
        }
    }

    @media (min-width: 768px) {
        #bx-soa-total-mobile {
            display: none;
        }
    }

    @media (max-width: 767px) {
        #bx-soa-order {
            grid-template-columns: 100%;
        }

        .bx-soa-pp-item-container {
            grid-template-columns: repeat(2, 1fr);
        }

        .bx-soa-pp {
            grid-template-columns: 100%;
        }
    }
}
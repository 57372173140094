.filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    --marker-color: #{$green};
    --tooltip-color: #{$green};
    --value-color: #{$green};
    --connect-color: #{$green};
    --handle-color: #{$green};
    --connects-color: #{rgba($green, .5)};

    .toggle {
        display: flex;
        align-items: center;
        text-transform: uppercase;

        .icon {
            width: 12px;
            height: 12px;
            margin-left: 5px;
            fill: currentColor;
        }
    }

    .filter-property {
        padding: 40px 0;
        margin-left: 20px;
        margin-right: 20px;
        border-top: 1px solid #DADADA;

        .filter-name {
            margin-bottom: 16px;
            text-transform: uppercase;
        }

        .custom-checkbox {
            &:not(:last-child):not(.color-checkbox) {
                margin-bottom: 16px;
            }
        }
    }

    .colors {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }

    .color-checkbox {
        .color {
            width: 10px;
            height: 10px;
            margin-right: 8px;
            border-radius: 50%;
            background: var(--color);
        }

        &:not(:has(:checked)) {
            opacity: 0.5;
        }
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 100%;
        gap: 20px;
        margin-top: 20px;
        margin-bottom: 20px;

        .tag {
            display: flex;
            align-items: center;
            padding: 5px 11px 5px 15px;
            font-size: 16px;
            line-height: 1.2;
            color: #BDBDBD;
            background: $gray;
            border-radius: 40px;

            .icon {
                width: 8px;
                height: 8px;
                margin-left: 11px;
                fill: currentColor;
            }
        }

        &.request {
            pointer-events: none;
        }
    }
}

.modal-filters {
    --close-bg-color: transparent;
    --close-icon-color: #{$green};
    --container-bg-color: #{$white};

    .modal {
        &__container {
            width: 33%;
            height: 100%;
            margin-right: 0;
            overflow: hidden;
        }

        &__overlay {
            padding: 0;
        }
    }

    .smart-filter {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
    }

    .modal-title {
        padding: 20px 20px 44px;
        text-transform: uppercase;
    }

    .smart-filter-popup-result {
        display: none;
    }

    .buttons {
        position: sticky;
        bottom: 0;
        margin-top: auto;
        display: flex;
        gap: 10px;
        padding: 20px;
        z-index: 1;
        background: $gray;

        .btn {
            flex: 1 1 auto;
        }

        &.request {
            pointer-events: none;
        }
    }

    @media (max-width: 1279px) {
        .modal {
            &__container {
                width: 45%;
            }
        }
    }

    @media (max-width: 743px) {
        .modal {
            &__container {
                width: 60%;
            }
        }
    }

    @media (max-width: 575px) {
        .modal {
            &__container {
                width: 100%;
            }
        }
    }
}
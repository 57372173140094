.submit-btn {
    position: relative;

    .preloader-icon,
    .ok-icon {
        display: none;
    }

    &.htmx-request {
        --text-color: transparent;
        pointer-events: none;
        transition-duration: 0s;

        .preloader-icon {
            display: block;
        }
    }

    &.success {
        --text-color: transparent;
        pointer-events: none;

        .ok-icon {
            display: block;
        }
    }

    &:disabled,
    &.htmx-request,
    &.success {
        pointer-events: none;
        transition-duration: 0s;
    }

    .preloader-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
    }
}
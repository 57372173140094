@import "@/scss/base/base.scss";

.dropdown-list {
    position: relative;
    z-index: 2;

    .current-row {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        text-transform: uppercase;

        .icon {
            width: 10px;
            height: 6px;
            margin-left: 13px;
            fill: currentColor;
            transition: transform .4s;
        }
    }

    .values {
        position: absolute;
        left: -20px;
        top: -15px;
        min-width: calc(100% + 40px);
        padding: 44px 20px 20px;
        background: #fff;
        box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.04);
    }

    .value {
        display: block;
        font-size: 12px;
        text-align: left;
        white-space: nowrap;
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &:not(.active) {
            opacity: 0.5;
        }
    }

    .current-value {
        font-size: 12px;
    }

    &:not(.open) {
        .values {
            display: none;
        }
    }

    &.open {
        .current-row {
            .icon {
                transform: rotateX(180deg);
            }
        }
    }
}
.range-wrap {
    .inputs {
        display: flex;
        margin-bottom: 10px;
        gap: 10px;
    }

    .delimeter {
        width: 20px;
        height: 2px;
        align-self: center;
        background: $green;
    }

    .form-input-wrap {
        flex: 1 1 auto;
    }
}
.refund-section {
    .title-row {
        display: flex;
    }

    .file-link {
        margin-top: 20px;
    }

    .title {
        flex: 0 0 50%;
    }

    .text-col {
        flex: 0 0 44%;
        margin-top: 14px;
    }

    .faq-list {
        margin-top: 50px;
    }

    @media (max-width: 1279px) {
        .title-row {
            flex-direction: column;
        }

        .title {
            font-size: 40px;
        }

        .faq-list {
            margin-top: 80px;
        }
    }

    @media (max-width: 575px) {
        .title {
            font-size: 32px;
        }

        .faq-list {
            margin-top: 40px;
        }

        .file-link {
            width: 100%;
        }
    }
}
.products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media (max-width: 1279px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 743px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 36px 13px;
    }
}
.product-card {
    overflow: hidden;
    position: relative;

    .name {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .old-price {
        color: #BDBDBD;
        text-decoration: line-through;
    }

    .top {
        margin-bottom: 20px;
        position: relative;
        aspect-ratio: 310/480;
        background: $gray;
    }

    .slider-pagination {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        z-index: 1;
    }

    .actions {
        position: absolute;
        top: 10px;
        right: 15px;
        display: flex;
        gap: 6px;
        z-index: 1;
        --action-bg-color: transparent;
        --action-icon-color: #E0E0E0;
        --action-icon-active-color: #{$green};
    }

    .product-photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform $tr-time
    }

    .sale {
        position: absolute;
        top: 24px;
        left: 24px;
        z-index: 1;
        color: $red;
        text-transform: uppercase;
    }

    .swiper-slide{
        overflow: hidden;
    }

    
    @media (min-width: 1280px){
        &:hover{
            .product-photo{
                transform: scale(1.15);
            }
        }
    }

    @media (max-width: 1279px) {
        .name {
            margin-bottom: 6px;
        }

        .name,
        .price {
            font-size: 14px;
        }
    }
}
.user-action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: var(--action-bg-color);
    transition: opacity $tr-time;

    .icon {
        width: 100%;
        height: 100%;
        fill: var(--action-icon-color);
    }

    &.active {
        .icon {
            fill: var(--action-icon-active-color);
        }
    }

    &.has-count {
        position: relative;

        .count {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 2px 4px;
            min-width: 12px;
            color: #fff;
            font-size: 8px;
            line-height: 1;
            border-radius: 50%;
            background: $red;
        }
    }

    &--rounded {
        border: 1px solid var(--action-icon-color);
        border-radius: 50%;
    }

    &:hover {
        opacity: 0.5;
    }
}
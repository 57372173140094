.form {
    &-input {
        display: block;
        width: 100%;
        height: 54px;
        padding: 0 20px 0;
        font-size: 12px;
        color: var(--form-text-color);
        background: var(--form-bg-color);
        border: 1px solid var(--form-border-color);
        transition: border-color $tr-time;

        &::placeholder {
            font-size: 16px;
            color: var(--form-text-color);
            opacity: 0.5;
        }

        &.error {
            border-color: var(--form-error-color) !important;
        }

        &:focus,
        &:not(:placeholder-shown) {
            +.form-placeholder {
                transform: translateY(-50%) scale(0.6);
                color: var(--form-placeholder-active-color);
            }
        }

        &.textarea {
            height: 140px;
            padding-top: 20px;
            padding-bottom: 20px;
            resize: none;
        }


        &-wrap {
            position: relative;
        }
    }

    &-placeholder {
        margin: 0 0 10px;
        color: var(--form-placeholder-color);
        font-size: 12px;
        line-height: 1;

        &.required {
            &::after {
                content: " *";
                color: var(--form-required-color);
            }
        }
    }
}

.dropdown-input {
    display: flex;
    align-items: center;

    .dropdown-list {
        flex: 1 0 auto;

        .current-row {
            justify-content: space-between;
        }
    }

}

select.form-input {
    appearance: none;
}

.personal {
    font-size: 12px;
    line-height: 1.2;
    color: var(--form-personal-color);

    &__link {
        color: var(--form-personal-link-color);
    }

    @media (max-width: 1279px) {
        font-size: 14px;
    }
}

.ok-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: r(42);
    height: r(29);
    margin: auto;
    transform: scale(var(--form-status-scale));
    fill: var(--form-status-icon-color);
}

.preloader-icon {
    width: var(--form-preloader-size);
    height: var(--form-preloader-size);
    fill: var(--form-status-icon-color);
    animation: spin 2s linear 0s infinite normal forwards;
}
.content-gallery-item {
    position: relative;

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform $tr-time;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(17, 15, 29, 0.7);
        opacity: 0;
        transition: opacity $tr-time;
    }

    &__zoom {
        width: r(30);
        height: r(30);
        fill: $white;
    }

    &__wrap {
        display: block;
        position: relative;
        aspect-ratio: var(--gallery-item-aspect-ratio);
        border-radius: var(--img-border-radius);
        overflow: hidden;
    }

    &__caption {
        margin-top: .5em;
        font-size: var(--caption-font-size);
        line-height: 1.2;
    }

    &:hover {
        .content-gallery-item {
            &__overlay {
                opacity: 1;
            }

            &__img {
                transform: scale(1.15);
            }
        }
    }
}

@media (max-width: 1279px) {
    .content-gallery-item {
        &__caption {
            margin-top: r(8);
            font-size: r(12);
        }
    }
}
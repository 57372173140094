.page-head {
    margin: 40px 0 76px;

    +.page-block {
        margin-top: 0;
    }

    @media (max-width: 1279px) {
        margin: 20px 0 40px;
    }
}
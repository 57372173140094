.about-section {

    .text-wrap {
        padding-right: 64px;
    }

    .description {
        text-transform: uppercase;
        font-weight: 500;
    }

    .photo-col {
        margin-top: 90px;
        position: relative;
        aspect-ratio: 1/1;
        grid-row: span 2;

        .bg__img {
            object-position: center top;
        }
    }

    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    }

    .title {
        margin-bottom: 30px;
    }

    .founders-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        margin-right: 20px;

        .founders {
            margin-bottom: 10px;
            text-transform: uppercase;
            color: #BDBDBD;
        }

        .photo-1,
        .photo-2 {
            width: 200px;
            aspect-ratio: 1/1;
            position: absolute;
            object-fit: cover;
            object-position: center;
        }

        .photo-1 {
            right: 200px;
            bottom: 200px;
        }

        .photo-2 {
            bottom: 0;
            right: 0;
        }
    }

    @media (max-width: 1279px) {
        .container {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }

        .text-wrap {
            padding-right: 0;
        }

        .title {
            margin-bottom: 20px;
            font-size: 40px;
        }

        .description {
            font-size: 16px;
        }

        .photo-col {
            margin-top: 20px;
            aspect-ratio: 672/480;
        }

        .founders-block {
            flex-direction: row;
            margin: 16px 0 0;
            gap: 20px;

            .photo-1,
            .photo-2 {
                position: static;
            }
        }

        .founders-info {
            align-self: flex-end;
            flex: 1 0 auto;
        }
    }

    @media (max-width: 767px) {}

    @media (max-width: 575px) {
        .photo-col {
            aspect-ratio: 343/300;
        }

        .title {
            font-size: 32px;
        }

        .founders-block {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;

            .founders,
            .founders-name {
                font-size: 14px;
            }

            .photo-1,
            .photo-2 {
                width: 100%;
            }
        }

        .founders-info {
            order: 1;
        }
    }
}
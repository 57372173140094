.certificates-section {
    --gallery-item-aspect-ratio: 310/452;

    .title {
        margin-bottom: 30px;
    }

    @media (max-width: 1279px) {
        .title {
            margin-bottom: 40px;
        }

        .slider-wrap {
            padding-right: 33px;
        }
    }
}
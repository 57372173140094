.slider-pagination {
    display: flex;
    justify-content: center;
    gap: 6px;

    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #E0E0E0;
        transition: background-color $tr-time;

        &.swiper-pagination-bullet-active {
            background: $green;
        }
    }
}
.delivery-section {
    .title-row {
        display: flex;
        margin-bottom: 76px;
    }

    .title {
        flex: 0 0 50%;
    }

    .description {
        margin-top: 11px;
        flex: 0 0 20%;
        font-weight: 500;
        text-transform: uppercase;
    }

    @media (max-width: 1279px) {
        .title-row {
            flex-direction: column;
            margin-bottom: 80px;
        }

        .title {
            font-size: 40px;
        }

        .description {
            width: 60%;
            margin-top: 20px;
            font-size: 16px;
        }
    }

    @media (max-width: 575px) {
        .title {
            font-size: 32px;
        }

        .title-row {
            margin-bottom: 40px;
        }

        .description {
            width: 80%;
        }
    }
}
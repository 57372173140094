.not-found-page {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  --darken-opacity: 0.4;
  .title {
    text-align: center;
  }
  .top {
    padding: 40px 0 75px;
  }
  .bottom {
    flex: 1 1 auto;
    position: relative;
    padding: 20px 0 0;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .text {
      width: 22%;
      margin-bottom: 30px;
      color: $white;
      text-align: center;
    }
  }
  @media (max-width: 1279px) {
    height: auto;
    .top {
      padding: 60px 0;
    }
    .title {
      font-size: 40px;
    }
    .bottom {
      height: 560px;
      .text {
        width: 80%;
      }
    }
  }
  @media (max-width: 575px) {
    .title {
      font-size: 32px;
    }
  }
}

.review-card {
    padding: 30px 30px 50px;
    background: $gray;
    --star-size: 14px;
    --star-active-color: #{$green};

    .rating-stars {
        margin-top: 13px;
    }

    .name {
        text-transform: uppercase;
    }

    .photo {
        position: relative;
        width: 63px;
        aspect-ratio: 1/1;
        margin-bottom: 40px;
        z-index: 1;
        overflow: hidden;
        border-radius: 50%;
    }

    .text {
        margin-top: 13px;
    }

    @media (max-width: 1279px) {

        .name,
        .text {
            font-size: 14px;
        }
    }
}
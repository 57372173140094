@use "sass:math";

$tr-time: 0.4s;

$green: #264945;
$white: #fff;
$red: #A8182F;
$gray: #F9F9F9;

@mixin h1 {
    font-size: 48px;

    @media (max-width: 1279px) {
        font-size: 40px;
    }

    @media (max-width: 575px) {
        font-size: 32px;
    }
}

@mixin text-48 {
    font-size: 48px;
    line-height: 1.2;
}

@mixin text-32 {
    font-size: 32px;
    line-height: 1.2;
}

@mixin text-20 {
    font-size: 20px;
    line-height: 1.2;
}

@mixin text-16 {
    font-size: 16px;
    line-height: 1.2;
}

@mixin text-14 {
    font-size: 14px;
    line-height: 1.2;
}

@mixin text-12 {
    font-size: 12px;
    line-height: 1.2;
}

@function calcFluid($f-min, $f-max, $v-min: 320px, $v-max: 1279px) {
    $k: math.div($f-max - $f-min, $v-max - $v-min);
    $b: $f-min - $k * $v-min;

    $b: $b * 1px;

    @return calc(#{$k} * 100vw + #{$b});
}

@function r($sizePX, $baseSizePX: 15) {
    @return #{math.div($sizePX, $baseSizePX)}rem;
}

@mixin calcFluid($f-min, $f-max, $property, $v-min: 320, $v-max: 1279) {
    --k: calc((#{$f-max} - #{$f-min}) / (#{$v-max} - #{$v-min}));
    --b: calc((#{$f-min} - var(--k) * #{$v-min}) * 1px);

    #{$property}: calc(var(--k) * 100vw + var(--b));
}
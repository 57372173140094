.lookbook {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    aspect-ratio: 2/1;

    .title {
        max-width: 26%;
        color: $white;
        text-align: center;
        text-transform: uppercase;
    }

    .link {
        margin-top: 10px;
    }

    .left-bg {
        width: 50%;
        right: unset;
    }

    .right-bg {
        width: 50%;
        left: unset;
    }

    @media (max-width: 1279px) {
        aspect-ratio: 744/400;

        .title {
            max-width: 55%;
            font-size: 16px;
        }

        .link {
            margin-top: 20px;
        }
    }

    @media (max-width: 575px) {
        padding: 20px 16px;
        justify-content: space-between;
        aspect-ratio: 375/480;

        .link {
            align-self: flex-start;
        }

        .left-bg {
            width: 100%;
        }

        .right-bg {
            display: none;
        }
    }
}
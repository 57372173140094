.bonus-card-alt2 {
    .bonus-card {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1300/580;
        --darken-opacity: .4;

        .title {
            width: 50%;
            position: relative;
            z-index: 1;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            color: $white;
        }
    }

    @media (max-width: 1279px) {
        .bonus-card {
            .title {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 575px) {
        .bonus-card {
            align-items: flex-start;
            aspect-ratio: auto;
            padding: 30px;
            min-height: 480px;

            .title {
                width: 100%;
            }
        }
    }
}
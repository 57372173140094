.main-screen {
    padding: 30px 0;
    height: calc(100dvh - 58px);
    position: relative;
    --darken-opacity: .4;

    .title {
        width: 33%;
        margin: 0 auto;
        color: $white;
        text-align: center;
        text-transform: uppercase;
    }

    @media (max-width: 1279px) {
        padding: 40px 0;

        .title {
            width: 58%;
        }
    }

    @media (max-width: 575px) {
        padding: 20px 0;

        .title {
            width: 85%;
            font-size: 16px;
        }
    }
}
.payment-section {
    .title {
        margin-bottom: 50px;
    }

    .img {
        width: 100%;
        margin-top: 60px;
    }

    @media (max-width: 1279px) {
        .img {
            margin-top: 40px;
        }

        .title {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 575px) {
        .img {
            margin-top: 16px;
        }
    }
}
.product-section {
    --star-size: 14px;
    --star-color: #bdbdbd;
    --star-active-color: #{$green};
    --action-icon-active-color: #{$green};

    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .title {
        text-transform: uppercase;
        font-weight: 500;
    }

    .price {
        margin-top: 10px;
        font-weight: 500;
    }

    .articul {
        margin-top: 10px;
        color: #bdbdbd;
    }

    .rating {
        margin-top: 30px;
    }

    .reviews-link {
        display: block;
        margin-bottom: 10px;
        text-decoration: underline;
    }

    .product-description {
        margin-top: 30px;
    }

    .color-picker {
        display: flex;
        gap: 10px;
        margin-top: 30px;

        .product-item-scu-item-color-container {
            width: 14px;
            height: 14px;
            padding: 1px;
            border: 1px solid transparent;
            border-radius: 50%;
            cursor: pointer;

            &.selected {
                border-color: #333;
            }
        }

        .product-item-scu-item-color {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .sizes-table-link {
        margin-top: 15px;
        text-decoration: underline;
    }

    .size {
        display: flex;
        align-items: center;
        margin-top: 35px;
        gap: 10px;
        line-height: 1;
        text-transform: uppercase;
    }

    .actions-row {
        display: flex;
        align-items: center;
        margin-top: 30px;
        --action-icon-color: #e0e0e0;

        .btn {
            &:not(.active) {
                display: none;
            }
        }
    }

    .favourite-action {
        margin-left: 18px;
    }

    .authorize {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px;
        margin-top: 53px;
        background: $gray;

        .authorize-title {
            flex: 0 0 55%;
            font-weight: 500;
            text-transform: uppercase;
        }

        .cashback {
            font-weight: 500;
        }

        .time {
            flex: 0 0 30%;
            text-align: right;
        }

        .register-link {
            flex: 1 1 50%;
            align-self: flex-end;
            text-decoration: underline;
            text-align: left;
        }
    }

    .faq-list {
        margin-top: 30px;
    }

    .help {
        margin-top: 20px;
        color: #bdbdbd;

        .link {
            color: $green;
            text-decoration: underline;
        }
    }

    .slider-col {
        position: relative;
        overflow: hidden;
        align-self: flex-start;
        --darken-opacity: 0.1;

        .nav-arrow {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;

            &.prev {
                left: 20px;
            }

            &.next {
                right: 20px;
            }
        }

        .main-swiper {
            .img {
                width: 100%;
                aspect-ratio: 639/747;
                object-fit: cover;
                object-position: top;
            }

            .swiper-slide {
                height: auto;
            }

            iframe {
                width: 100%;
                height: 100%;
            }
        }

        .thumbs-swiper {
            margin-top: 10px;

            .img {
                width: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;
            }

            .swiper-slide {
                background: #d9d9d9;
            }
        }

        .slider-pagination {
            margin-top: 16px;
        }
    }

    .send-review {
        margin-top: 10px;
    }

    @media (min-width: 744px) {
        .slider-col {
            .slider-pagination {
                display: none;
            }
        }
    }

    @media (max-width: 743px) {
        .slider-col {
            .thumbs-swiper {
                display: none;
            }
        }

        .actions-row {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 16px;
            z-index: 2;
            background: $gray;

            .btn {
                flex: 1 1 auto;
            }

            .user-action {
                width: 46px;
                height: 46px;
            }
        }

        .favourite-action {
            margin-left: 5px;
        }
    }

    @media (max-width: 1279px) {
        .container {
            grid-template-columns: 100%;
            gap: 40px;
        }

        .price,
        .title {
            font-size: 16px;
        }

        .reviews-lin,
        .sizes-table-link {
            font-size: 14px;
        }

        .authorize {
            flex-direction: column;
            margin-top: 40px;

            .register-link,
            .time {
                font-size: 14px;
            }

            .time {
                margin-bottom: 30px;
                text-align: left;
            }

            .register-link {
                order: 1;
                align-self: flex-start;
            }

            .authorize-title {
                margin-bottom: 30px;
            }
        }

        .help {
            font-size: 14px;
        }
    }
}
.gift-certificates-section {
    .grid {
        display: grid;
        gap: 20px;
        grid-template-columns: 74.6% 1fr;
    }

    .steps {
        margin-top: 100px;
        grid-column: span 2;
    }

    .img {
        width: 100%;
    }

    .form-col {
        display: flex;
        flex-direction: column;

        .help {
            margin-top: auto;
            color: #BDBDBD;

            .link {
                color: $green;
                text-decoration: underline;
            }
        }

        .title {
            margin-bottom: 30px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .description {
            margin-bottom: 30px;
        }

        .form {
            display: grid;
            grid-template-columns: 1fr;
            gap: 24px;
        }
    }

    @media (max-width: 1279px) {
        .grid {
            grid-template-columns: 1fr;
            gap: 0;
        }

        .steps {
            grid-column: auto;
        }

        .steps-title {
            font-size: 16px;
        }

        .img-col {
            margin-bottom: 40px;
        }

        .form-col {
            .title {
                font-size: 16px;
            }

            .description {
                font-size: 14px;
            }

            .help {
                margin-top: 20px;
            }
        }
    }

    @media (max-width: 743px) {
        .img-col {
            grid-row: 2/3;
        }

        .form-col {
            margin-bottom: 40px;
        }
    }
}

.modal-payment-result {
    .title {
        margin-bottom: 20px;
        font-weight: 500;
        text-align: center;
    }

    .text {
        text-align: center;
    }
}
.advantages-section {
    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 4px;
    }

    .advantage {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        aspect-ratio: 1/1;
        background: $gray;
        border-radius: 50%;

        .title,
        .text {
            width: 65%;
            text-align: center;
        }

        .title {
            margin-bottom: 10px;
        }
    }

    @media (max-width: 1279px) {
        .advantage {
            .title {
                font-size: 40px;
            }

            .text {
                font-size: 14px;
            }
        }

        .grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 575px) {
        overflow: visible;

        .advantage {
            width: 217px;
            flex: 0 0 auto;

            .title {
                font-size: 32px;
            }
        }

        .grid {
            display: flex;
            overflow: auto;
            padding: 0 16px;
            margin: 0 -16px;
        }
    }
}
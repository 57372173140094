.breadcrumbs {
    ul {

        li {
            display: inline;
            font-size: 12px;

            &:last-child {
                color: #BDBDBD;
            }

            &:not(:first-child) {
                text-transform: lowercase;
            }
        }
    }
}
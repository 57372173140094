.tabs {
    display: flex;

    .tab {
        flex: 1 1 10%;
        padding-bottom: 11px;
        text-align: left;
        border-bottom: 1px solid $green;

        &:not(.active) {
            opacity: 0.5;
        }
    }
}
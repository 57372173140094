.search-select {
    position: relative;

    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 20px;
        background: var(--select-bg-color);
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: var(--form-border-color);
    }
}
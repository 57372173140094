.recommendations-section {
    --darken-opacity: .1;

    .title {
        margin-bottom: 65px;
    }

    .tabs {
        margin-bottom: 38px;
    }

    .tab-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .text-col {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 20px;
            position: relative;
        }

        .img-col {
            align-self: flex-start;
        }

        .small-img {
            width: 200px;
            aspect-ratio: 1/1;
        }

        .small-img-wrap {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .text {
            font-weight: 500;
            text-transform: uppercase;
        }

        .img {
            width: 100%;
            aspect-ratio: 1/.85;
            object-fit: cover;
            object-position: top center;
        }

        .requirements {
            list-style: none;
        }
    }

    @media (max-width: 1279px) {
        .title {
            margin-bottom: 40px;
            font-size: 40px;
        }

        .tabs {
            margin-bottom: 20px;
            overflow: auto;

            .tab {
                min-width: 153px;
            }
        }

        .tab-content {
            .small-img-wrap {
                display: none;
            }

            .text {
                font-size: 16px;
            }

            .requirements {
                margin-top: 20px;
                font-size: 14px;
            }
        }
    }

    @media (max-width: 767px) {
        .tab-content {
            grid-template-columns: 1fr;

            .text-col {
                margin: 0 0 20px;
            }
        }
    }

    @media (max-width: 575px) {
        .title {
            font-size: 32px;
        }

        .tabs {
            margin-bottom: 30px;
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;
        }

        .tab-content {
            .requirements {
                margin-top: 30px;
            }
        }
    }
}
.rating-stars {
    display: flex;
    gap: 3px;

    .star {
        flex: 0 0 auto;
        width: var(--star-size);
        height: var(--star-size);

        .icon {
            width: 100%;
            height: 100%;
            fill: var(--star-color, #BDBDBD);
            transition-duration: 0s;
        }

        &.active {
            .icon {
                fill: var(--star-active-color, #{$green});
            }
        }
    }
}
.show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    text-align: center;
    font-size: 12px;
    line-height: 1.2;

    .link {
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: scale(1.5);
    }

    .icon {
        width: 10px;
        height: 6px;
        margin-top: 9px;
        fill: currentColor;
    }

    @media (max-width: 1279px) {
        margin-top: 40px;
    }
}
.bonus-card-alt {
    .bonus-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        aspect-ratio: 1300/555;
        --darken-opacity: .6;

        .title,
        .text {
            width: 24%;
            position: relative;
            z-index: 1;
            text-align: center;
            color: $white;
        }

        .title {
            text-transform: uppercase;
            font-weight: 500;
        }

        .text {
            margin-top: 20px;
        }

        .btn {
            margin-top: 20px;
        }
    }

    @media (max-width: 1279px) {
        .bonus-card {
            aspect-ratio: 672/300;

            .text,
            .title {
                width: 50%;
            }

            .title {
                font-size: 16px;
            }

            .text {
                font-size: 14px;
            }
        }
    }

    @media (max-width: 767px) {

        .bonus-card {
            padding: 20px;
            aspect-ratio: auto;
            height: 480px;
            --darken-opacity: 0;

            .title,
            .text {
                width: 60%;
            }
        }
    }

    @media (max-width: 575px) {
        .bonus-card {

            .text {
                margin-top: 16px;
            }

            .btn {
                position: absolute;
                bottom: 20px;
                left: 20px;
                right: 20px;
            }
        }
    }
}
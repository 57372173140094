.basket-total {
    position: sticky;
    top: 80px;
    right: 0;
    align-self: flex-start;
    padding: 20px;
    background: $gray;

    .total {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 500;
    }

    .product-count {
        margin-bottom: 20px;
    }

    .submit {
        margin-top: 20px;
        width: 100%;
    }

    .price-info {
        display: flex;
        justify-content: space-between;

        .value {
            color: #BDBDBD;
        }
    }

    .price-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 20px 0 0;
    }

    .basket-coupons {
        margin: 20px 0 0;

        .close-link {
            cursor: pointer;
        }
    }

    .bonus-text {
        margin: 10px 0;
        color: #BDBDBD;
    }

    .login {
        margin-top: 20px;
        text-decoration: underline;
    }

    @media (max-width: 1279px) {
        position: static;
    }
}
.range-slider {
    .noUi {
        &-marker-large {
            display: none;
        }

        &-horizontal {
            height: 18px;

            .noUi-handle {
                width: r(10);
                height: r(10);
                top: 0;
                right: 0;
                transform: translate(50%, 42%);

                &::before,
                &::after {
                    display: none;
                }
            }
        }

        &-handle {
            background: var(--handle-color);
            border: 0;
            border-radius: 50%;
            box-shadow: none;
        }

        &-target {
            background: transparent;
            border: 0;
            box-shadow: none;
        }

        &-base {
            display: flex;
            align-items: center;
        }

        &-pips {
            display: flex;
            justify-content: space-between;
            height: 100%;
            top: 0;

            &-horizontal {
                height: auto;
                padding: 0;
            }
        }

        &-connect {
            background: var(--connect-color);
        }

        &-connects {
            height: r(2);
            background: var(--connects-color);
            border-radius: 0;
        }

        &-value {
            position: static;
            margin-top: 15px;
            font-size: r(12);
            line-height: 1.2;
            color: var(--value-color);

            &-horizontal {
                transform: none;
            }
        }

        &-tooltip {
            padding: 0 0 r(8);
            font-size: r(18);
            line-height: 1.4;
            color: var(--tooltip-color);
            border: 0;
            background: transparent;
        }

        &-marker {
            background: var(--marker-color);
        }

        &-marker-horizontal.noUi-marker {
            height: r(16);
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}
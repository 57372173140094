.default-form-colors {
    --form-border-color: #E0E0E0;
    --form-text-color: #{$green};
    --form-input-placeholder-color: #BDBDBD;
    --form-personal-color: #BDBDBD;
    --form-personal-link-color: #{$green};
    --form-status-icon-color: #{$green};
    --form-status-scale: 1;
    --form-preloader-size: 30px;
    --form-placeholder-color: #BDBDBD;
    --form-error-color: #f00;
    --form-bg-color: transparent;
    --select-bg-color: #fff;
}
.catalog-section-page {
    .items-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 100px 20px;
        margin-top: 30px;
    }

    .title {
        margin-bottom: 60px;
    }

    @media (max-width: 743px) {
        .title {
            margin-bottom: 40px;
        }
    }
}
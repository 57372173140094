.actions-slider-section {
    overflow: hidden;

    .title {
        margin-bottom: 40px;
    }

    .action {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        aspect-ratio: 640/500;
        color: $white;
        --darken-opacity: .3;

        &-title {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translate(0, -50%);
            text-transform: uppercase;
            font-weight: 500;
            text-align: center;
        }

        .description {
            width: 60%;
            margin-top: auto;
            text-align: center;
        }
    }

    .simple-slider {
        width: 49.3%;
    }

    .swiper {
        overflow: visible;
    }

    @media (max-width: 1279px) {
        .simple-slider {
            width: 59.6%;

            .slider-wrap {
                overflow: visible;
            }
        }

        .title {
            font-size: 40px;
        }

        .action {
            &-title {
                font-size: 16px;
            }

            .description {
                width: 100%;
                font-size: 14px;
            }
        }

    }

    @media (min-width: 576px) {
        .pagination-wrap {
            display: none;
        }
    }

    @media (max-width: 575px) {
        .simple-slider {
            width: 100%;
        }

        .action {
            padding: 30px 19px;
            aspect-ratio: 343/480;

            &-title {
                position: static;
            }
        }

        .title {
            font-size: 32px;
        }

        .pagination-wrap {
            position: static;
            margin-top: 16px;
        }
    }
}
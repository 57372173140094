.contacts-section {
    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .map-col {
        width: 100%;
        aspect-ratio: 650/530;
    }

    .left-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .title {
        margin-bottom: 80px;
    }

    .contacts {
        width: 55%;
    }

    .email {
        display: block;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .contact {
        margin-bottom: 40px;

        .contact-title {
            margin-bottom: 20px;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    @media (max-width: 1279px) {
        .title {
            margin-bottom: 40px;
            font-size: 40px;
        }
    }

    @media (max-width: 767px) {
        .map-col {
            aspect-ratio: 672/480;
        }

        .container {
            grid-template-columns: 100%;
            gap: 42px;
        }
    }

    @media (max-width: 575px) {
        .title {
            font-size: 32px;
        }

        .contacts {
            width: 100%;
        }

        .container {
            gap: 20px;
        }

        .link {
            width: 100%;
        }

        .map-col {
            aspect-ratio: 343/300;
        }
    }
}
.news-page {
    .title {
        margin-bottom: 45px;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 87px 20px;
    }

    @media (max-width: 1279px) {
        .title {
            font-size: 40px;
        }

        .grid {
            grid-template-columns: 1fr 1fr;
            gap: 40px 13px;
        }
    }

    @media (max-width: 575px) {
        .title {
            font-size: 32px;
        }

        .grid {
            grid-template-columns: 1fr;
            gap: 40px;
        }
    }
}
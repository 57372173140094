.lookbook-card {
    height: 580px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: relative;
    padding: 30px;
    --darken-opacity: .4;
    --link-hover-color: #{$white};

    .name {
        text-transform: uppercase;
        font-weight: 500;
        color: $white;
        text-align: center;
    }

    &.big {
        grid-column: span 3;
    }

    @media (max-width: 1279px) {
        padding: 20px;
        height: 320px;

        .name {
            font-size: 16px;
        }
    }

    @media (max-width: 575px) {
        height: 480px;

        &.big {
            grid-column: auto;
        }
    }
}
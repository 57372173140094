.custom-checkbox {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $green;
    cursor: pointer;

    input {
        display: none;

        &:checked+.checkbox {

            .checkbox-icon {
                opacity: 1;
            }
        }
    }

    .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: r(20);
        height: r(20);
        margin-right: r(12);
        flex: 0 0 auto;
        border: 1px solid $green;

        &-icon {
            width: r(10);
            height: r(9);
            fill: $green;
            opacity: 0;
        }
    }
}
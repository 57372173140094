.nav-arrow {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $green;
    border-radius: 50%;

    .icon {
        width: 8px;
        height: 17px;
        fill: $white;
    }

    &.prev {
        .icon {
            transform: rotate(-90deg);
        }
    }

    &.next {
        .icon {
            transform: rotate(90deg);
        }
    }

    &:disabled {
        opacity: 0;
        pointer-events: none !important;
    }
}